// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-news-index-js": () => import("./../../../src/components/News/index.js" /* webpackChunkName: "component---src-components-news-index-js" */),
  "component---src-components-post-index-js": () => import("./../../../src/components/Post/index.js" /* webpackChunkName: "component---src-components-post-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-finances-js": () => import("./../../../src/pages/app-finances.js" /* webpackChunkName: "component---src-pages-app-finances-js" */),
  "component---src-pages-app-frases-js": () => import("./../../../src/pages/app-frases.js" /* webpackChunkName: "component---src-pages-app-frases-js" */),
  "component---src-pages-apps-delete-account-js": () => import("./../../../src/pages/apps/delete-account.js" /* webpackChunkName: "component---src-pages-apps-delete-account-js" */),
  "component---src-pages-apps-financas-index-js": () => import("./../../../src/pages/apps/finanças/index.js" /* webpackChunkName: "component---src-pages-apps-financas-index-js" */),
  "component---src-pages-apps-finances-index-js": () => import("./../../../src/pages/apps/finances/index.js" /* webpackChunkName: "component---src-pages-apps-finances-index-js" */),
  "component---src-pages-apps-finances-plataforma-web-js": () => import("./../../../src/pages/apps/finances/plataforma-web.js" /* webpackChunkName: "component---src-pages-apps-finances-plataforma-web-js" */),
  "component---src-pages-apps-finances-premium-js": () => import("./../../../src/pages/apps/finances/premium.js" /* webpackChunkName: "component---src-pages-apps-finances-premium-js" */),
  "component---src-pages-apps-finances-terms-js": () => import("./../../../src/pages/apps/finances/terms.js" /* webpackChunkName: "component---src-pages-apps-finances-terms-js" */),
  "component---src-pages-apps-frases-index-js": () => import("./../../../src/pages/apps/frases/index.js" /* webpackChunkName: "component---src-pages-apps-frases-index-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-phrases-index-js": () => import("./../../../src/pages/apps/phrases/index.js" /* webpackChunkName: "component---src-pages-apps-phrases-index-js" */),
  "component---src-pages-apps-phrases-terms-js": () => import("./../../../src/pages/apps/phrases/terms.js" /* webpackChunkName: "component---src-pages-apps-phrases-terms-js" */),
  "component---src-pages-apps-productivity-index-js": () => import("./../../../src/pages/apps/productivity/index.js" /* webpackChunkName: "component---src-pages-apps-productivity-index-js" */),
  "component---src-pages-apps-productivity-premium-js": () => import("./../../../src/pages/apps/productivity/premium.js" /* webpackChunkName: "component---src-pages-apps-productivity-premium-js" */),
  "component---src-pages-apps-productivity-promotion-js": () => import("./../../../src/pages/apps/productivity/promotion.js" /* webpackChunkName: "component---src-pages-apps-productivity-promotion-js" */),
  "component---src-pages-apps-productivity-terms-js": () => import("./../../../src/pages/apps/productivity/terms.js" /* webpackChunkName: "component---src-pages-apps-productivity-terms-js" */),
  "component---src-pages-apps-produtividade-index-js": () => import("./../../../src/pages/apps/produtividade/index.js" /* webpackChunkName: "component---src-pages-apps-produtividade-index-js" */),
  "component---src-pages-black-friday-compra-individual-js": () => import("./../../../src/pages/black-friday-compra-individual.js" /* webpackChunkName: "component---src-pages-black-friday-compra-individual-js" */),
  "component---src-pages-black-friday-js": () => import("./../../../src/pages/black-friday.js" /* webpackChunkName: "component---src-pages-black-friday-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bonus-js": () => import("./../../../src/pages/bonus.js" /* webpackChunkName: "component---src-pages-bonus-js" */),
  "component---src-pages-combo-app-curso-js": () => import("./../../../src/pages/combo-app-curso.js" /* webpackChunkName: "component---src-pages-combo-app-curso-js" */),
  "component---src-pages-combo-app-ebook-js": () => import("./../../../src/pages/combo-app-ebook.js" /* webpackChunkName: "component---src-pages-combo-app-ebook-js" */),
  "component---src-pages-combo-apps-ebooks-curso-js": () => import("./../../../src/pages/combo-apps-ebooks-curso.js" /* webpackChunkName: "component---src-pages-combo-apps-ebooks-curso-js" */),
  "component---src-pages-combo-apps-js": () => import("./../../../src/pages/combo-apps.js" /* webpackChunkName: "component---src-pages-combo-apps-js" */),
  "component---src-pages-combo-ebooks-app-js": () => import("./../../../src/pages/combo-ebooks-app.js" /* webpackChunkName: "component---src-pages-combo-ebooks-app-js" */),
  "component---src-pages-combo-ebooks-curso-js": () => import("./../../../src/pages/combo-ebooks-curso.js" /* webpackChunkName: "component---src-pages-combo-ebooks-curso-js" */),
  "component---src-pages-combo-ebooks-produtividade-habitos-metas-js": () => import("./../../../src/pages/combo-ebooks-produtividade-habitos-metas.js" /* webpackChunkName: "component---src-pages-combo-ebooks-produtividade-habitos-metas-js" */),
  "component---src-pages-combos-js": () => import("./../../../src/pages/combos.js" /* webpackChunkName: "component---src-pages-combos-js" */),
  "component---src-pages-curso-domine-a-arte-da-produtividade-js": () => import("./../../../src/pages/curso-domine-a-arte-da-produtividade.js" /* webpackChunkName: "component---src-pages-curso-domine-a-arte-da-produtividade-js" */),
  "component---src-pages-curso-domine-seus-habitos-js": () => import("./../../../src/pages/curso-domine-seus-habitos.js" /* webpackChunkName: "component---src-pages-curso-domine-seus-habitos-js" */),
  "component---src-pages-cursos-index-js": () => import("./../../../src/pages/cursos/index.js" /* webpackChunkName: "component---src-pages-cursos-index-js" */),
  "component---src-pages-dozeroaobilhao-js": () => import("./../../../src/pages/dozeroaobilhao.js" /* webpackChunkName: "component---src-pages-dozeroaobilhao-js" */),
  "component---src-pages-ebooks-guia-completo-habitos-index-js": () => import("./../../../src/pages/ebooks/guia-completo-habitos/index.js" /* webpackChunkName: "component---src-pages-ebooks-guia-completo-habitos-index-js" */),
  "component---src-pages-ebooks-guia-definitivo-para-a-produtividade-index-js": () => import("./../../../src/pages/ebooks/guia-definitivo-para-a-produtividade/index.js" /* webpackChunkName: "component---src-pages-ebooks-guia-definitivo-para-a-produtividade-index-js" */),
  "component---src-pages-ebooks-index-js": () => import("./../../../src/pages/ebooks/index.js" /* webpackChunkName: "component---src-pages-ebooks-index-js" */),
  "component---src-pages-ebooks-metas-e-planejamento-index-js": () => import("./../../../src/pages/ebooks/metas-e-planejamento/index.js" /* webpackChunkName: "component---src-pages-ebooks-metas-e-planejamento-index-js" */),
  "component---src-pages-extensao-js": () => import("./../../../src/pages/extensao.js" /* webpackChunkName: "component---src-pages-extensao-js" */),
  "component---src-pages-guia-completo-habitos-e-metas-js": () => import("./../../../src/pages/guia-completo-habitos-e-metas.js" /* webpackChunkName: "component---src-pages-guia-completo-habitos-e-metas-js" */),
  "component---src-pages-guia-completo-habitos-js": () => import("./../../../src/pages/guia-completo-habitos.js" /* webpackChunkName: "component---src-pages-guia-completo-habitos-js" */),
  "component---src-pages-guia-definitivo-para-a-produtividade-js": () => import("./../../../src/pages/guia-definitivo-para-a-produtividade.js" /* webpackChunkName: "component---src-pages-guia-definitivo-para-a-produtividade-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-metas-e-planejamento-js": () => import("./../../../src/pages/metas-e-planejamento.js" /* webpackChunkName: "component---src-pages-metas-e-planejamento-js" */),
  "component---src-pages-newsletter-index-js": () => import("./../../../src/pages/newsletter/index.js" /* webpackChunkName: "component---src-pages-newsletter-index-js" */),
  "component---src-pages-newsletter-subscribed-js": () => import("./../../../src/pages/newsletter/subscribed.js" /* webpackChunkName: "component---src-pages-newsletter-subscribed-js" */),
  "component---src-pages-newsletter-unsubscribe-js": () => import("./../../../src/pages/newsletter/unsubscribe.js" /* webpackChunkName: "component---src-pages-newsletter-unsubscribe-js" */),
  "component---src-pages-plataforma-web-js": () => import("./../../../src/pages/plataforma-web.js" /* webpackChunkName: "component---src-pages-plataforma-web-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-ranking-js": () => import("./../../../src/pages/ranking.js" /* webpackChunkName: "component---src-pages-ranking-js" */),
  "component---src-pages-termos-de-uso-js": () => import("./../../../src/pages/termos-de-uso.js" /* webpackChunkName: "component---src-pages-termos-de-uso-js" */),
  "component---src-pages-termos-js": () => import("./../../../src/pages/termos.js" /* webpackChunkName: "component---src-pages-termos-js" */)
}

